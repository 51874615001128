<template>
  <div>
    <v-form>
      <p class="mb-3">
        A student drew the mechanism to show the activation of the carboxylic acid with DCC in the
        reaction. Is their mechanism correct?
      </p>
      <img
        :src="'/img/assignments/ChemUCI51LCP3PreQ5.png'"
        :alt="'image of tautomerization'"
        style="max-height: 150px"
      />
      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
export default {
  name: 'ChemUCI51LCP3PreQ5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {
          text: 'Yes, because the carbonyl needs to be activated with a proton.',
          value: 'a',
        },
        {
          text: 'No, because the $\\ce{HOBt}$ should be activating the carboxylic acid.',
          value: 'b',
        },
        {
          text: 'Yes, because you should have an alcohol at the end of the mechanism.',
          value: 'c',
        },
        {
          text: 'No, because carboxylate anion should attack the protonated DCC.',
          value: 'd',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
